import { useRecoilValue } from "recoil";
import { selectedProductToDupeSelector } from "../../../../state/selectors/selectedProductToDupeSelector";
import { useMemo } from "react";
import { truncateString } from "../../../utils/truncateString";
import { extractUnit } from "../../../../components/carousell/DupeCarousell/utils";
import { useParams } from "react-router-dom";
import { isPriceInRange } from "../../../utils/priceInRange";
import { t } from "i18next";
import { TProduct } from "../../../../types";
import { Price, PricePer100ml } from "../../../components/text/Price";

const CDN_URL =
  import.meta.env.VITE_CDN_URL || "https://www.claire.digital/cdn/claire/";

export const ProductToMatchCard = () => {
  const { gtin } = useParams();

  const selectedProductToDupe = useRecoilValue(
    selectedProductToDupeSelector(gtin)
  );

  const webpUrl = useMemo(
    () => selectedProductToDupe?.imagefront.replace(".png", ".webp"),
    [selectedProductToDupe?.imagefront]
  );

  const brandname = truncateString(selectedProductToDupe?.brand, 15);
  const productName = truncateString(selectedProductToDupe?.name, 30);
  const rangeName = truncateString(selectedProductToDupe?.range1, 24);

  return (
    <div className="flex w-full rounded-lg bg-white shadow-sm">
      <div className="w-1/3 rounded-l-lg bg-gray-200 p-4">
        <img
          className="h-20 w-20 rounded-l-lg object-contain"
          src={`${CDN_URL}/${webpUrl}`}
          alt=""
        />
      </div>
      <div className="flex w-2/3 flex-col rounded-r-lg bg-white p-4">
        <span
          className="font-circular-medium text-base uppercase"
          data-cy="original-product-brand"
        >
          {brandname}
        </span>
        <span className="font-circular-book text-xs text-black">
          {rangeName}
        </span>
        <span className="font-circular-book text-xs text-gray-300">
          {productName}
        </span>
        <PriceAndSize product={selectedProductToDupe} />
      </div>
    </div>
  );
};

const PriceAndSize = ({ product }: { product: TProduct | null }) => {
  if (!product) return null;

  const priceInRange = isPriceInRange(Number(product?.price_de));

  return (
    <span className="font-circular-book text-sm ">
      {priceInRange ? (
        <>
          <Price price={product?.price_de} />{" "}
        </>
      ) : (
        `${t("SHARED.PRICE_NA")} `
      )}
      <span className="font-circular-book text-xs text-gray-400">
        {product?.sizeml} |{" "}
        {priceInRange ? (
          <PricePer100ml price={product?.price_de} sizeml={product.sizeml} />
        ) : (
          t("SHARED.PRICE_NA")
        )}{" "}
        / 1 {product ? extractUnit(product.sizeml) : null}
      </span>
    </span>
  );
};
