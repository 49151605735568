import { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { calculateMatchPriceDifference } from "../../../../components/carousell/DupeCarousell/utils";
import { useElementOnScreen } from "../../../../hooks/useElementOnScreen";
import { classNames } from "../../../../shared";
import { currentlyVisibleMatchAtom } from "../../../../state/atoms/currentlyVisibleMatchAtom";
import { TDupeProduct, TProduct } from "../../../../types";
import { MatchCircle } from "../../../components/special/MatchCircle";
import { truncateString } from "../../../utils/truncateString";
import { calculateColorBrightness } from "../utils/calculateColorBrightness";
import { PriceSectionMatchCard } from "./PriceSectionMatchCard";
import { trackProductClick } from "../../../../Tracking/general/generalTrackingEvents";
import { isPriceInRange } from "../../../utils/priceInRange";

const CDN_URL =
  import.meta.env.VITE_CDN_URL || "https://www.claire.digital/cdn/claire/";

type TMatchCardProps = {
  match: TDupeProduct;
  index: number;
  productToMatch: TProduct;
  isLast: boolean;
};

export const MatchCard = ({
  match,
  productToMatch,
  index,
  isLast,
}: TMatchCardProps) => {
  const location = useLocation();
  const setCurrentlyVisibleMatch = useSetRecoilState(currentlyVisibleMatchAtom);

  const brandname = truncateString(match.data.brand, 15);
  const productName = truncateString(match.data.name, 20);
  const rangeName = truncateString(match.data.range1, 20);

  const [containerRef, isVisible] = useElementOnScreen({
    threshold: 0.6,
    reappear: true,
  });

  useEffect(() => {
    if (location.state?.previouslySelectedGtin === match.data.gtin) {
      containerRef.current?.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
      });
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      setCurrentlyVisibleMatch(match.data.colorhex);
    }
  }, [isVisible]);

  const priceDifference = useMemo(
    () => calculateMatchPriceDifference(productToMatch, match),
    [match, productToMatch]
  );

  const priceInRange =
    isPriceInRange(Number(productToMatch.price_de)) &&
    isPriceInRange(Number(match.data.price_de));

  const brightnessValue = calculateColorBrightness(match.data.colorhex);

  // make sure the circle is visbile if the match color is very bright
  const matchCircleColor =
    brightnessValue > 200 ? "black" : match.data.colorhex;

  return (
    <div
      data-cy="match-card"
      ref={containerRef}
      className={classNames(
        "relative ml-3 flex h-96 snap-center  flex-col  justify-between rounded-lg bg-white ",
        index === 0 ? "ml-6" : "",
        isLast ? "mr-6" : ""
      )}
    >
      <Link
        preventScrollReset={true}
        to={`/de/${match.gtin_originalproduct}/match-details/${match.gtin_twinproduct}`}
        state={{ from: location.pathname }}
        onClick={() => trackProductClick(match.data, index, "match_page")}
        className=" relative flex max-h-60 w-72 items-center  justify-start rounded-t-lg    bg-gray-100 p-4 pt-8"
      >
        <div className="flex w-1/2 justify-center ">
          <img
            className="h-40 w-20  object-contain"
            src={`${CDN_URL}/${match?.data.imagefront}`}
            alt=""
          />
        </div>

        <div className="absolute right-9 top-12 flex flex-col ">
          <MatchCircle
            color={matchCircleColor}
            percentage={parseInt(match.sim_total)}
            extraClassNames="h-28 w-28"
          >
            <div className="flex flex-col items-center justify-center">
              <span className=" font-ivypresto-regular text-3xl leading-9 ">
                {match.sim_total}%
              </span>
              <span className="font-circular-book text-xs ">
                MATCH<span>&#8202;</span>&sup1;
              </span>
            </div>
          </MatchCircle>
          {/* {priceInRange ? (
            <span className="relative mt-3  text-center font-circular-book text-xs">
              {" "}
              {priceDifference.value}
              <span className="text-sm text-gray-400">&sup1;</span>{" "}
              {priceDifference.label}
            </span>
          ) : null} */}
        </div>
      </Link>

      <Link
        preventScrollReset={true}
        onClick={() => trackProductClick(match.data, index, "match_page")}
        to={`/de/${match.gtin_originalproduct}/match-details/${match.gtin_twinproduct}`}
        state={{ from: location.pathname }}
        className="px-5 pb-4"
      >
        <div className="flex w-full transform flex-col pt-6 pb-3">
          <span className="font-circular-medium text-xl uppercase">
            {brandname}
          </span>
          <span className="font-circular-book text-base text-black">
            {rangeName}
          </span>
          <span className="mb-3 font-circular-book text-base text-gray-300">
            {productName}
          </span>
          <PriceSectionMatchCard product={match.data} />
        </div>
      </Link>
    </div>
  );
};
