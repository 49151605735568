import { formatPrice } from "../../utils/formatPrice";
import { extractNumberFromString } from "../../utils/extractNumberFromString";
import { t } from "i18next";

export const Price = ({ price }: { price: string | null | undefined }) => {
  if (!price) return null;

  return (
    <>
      {t("SHARED.FROM_LABEL")}
      <span>&sup2;</span> {formatPrice(price)}
      <span>&#8202;€</span>
      <span className="ml-0.5 text-base text-gray-400">&sup3;</span>
    </>
  );
};

export const PricePer100ml = ({
  price,
  sizeml,
}: {
  price: string | null | undefined;
  sizeml: string | null | undefined;
}) => {
  if (!price || !sizeml) return null;

  return (
    <>
      {`${formatPrice(
        ((Number(price) / extractNumberFromString(sizeml)) * 1000).toFixed(2)
      )}`}
      &#8202;€
    </>
  );
};
