import { t } from "i18next";
import { TDupeProduct, TProduct } from "../../../../types";

export const calculateMatchPriceDifference = (
  originalProduct: TProduct,
  dupe: TDupeProduct,
  cheaperLabel?: string,
  moreExpensiveLabel?: string
): { value: string; label: string } => {
  const cheaperLabelTrans = cheaperLabel || t("SHARED.SAVED_LABEL");
  const moreExpensiveLabelTrans =
    moreExpensiveLabel || t("SHARED.MORE_EXPENSIVE_LABEL");

  const priceDifferencePercent = getPriceDifference(
    Number(originalProduct.price_de),
    Number(dupe.data.price_de)
  );

  if (!priceDifferencePercent) {
    return {
      value: "",
      label: "",
    };
  }

  const sufix =
    Number(priceDifferencePercent) === 0
      ? ""
      : priceDifferencePercent?.includes("-")
      ? moreExpensiveLabelTrans
      : cheaperLabelTrans;

  const finalPriceDifference = `${
    Number(priceDifferencePercent) > 0 ? "-" : "+"
  }${priceDifferencePercent.replace("-", "")}%`;

  return {
    value: finalPriceDifference,
    label: sufix,
  };
};

export const extractUnit = (str: string): string => {
  const unitRegex = /[a-zA-Z]+$/;
  const match = str.match(unitRegex);
  if (match) {
    if (match[0] === "ml") {
      return "l";
    }

    if (match[0] === "g") {
      return "kg";
    }
  }
  return "";
};

export const calculatePriceFor1000ml = (
  volume: number,
  price: number
): number => {
  const priceFor1000ml = (price / volume) * 1000;
  return priceFor1000ml;
};

function getPriceDifference(priceOriginalProduct: number, priceDupe: number) {
  const priceDifference = priceOriginalProduct - priceDupe;

  return ((priceDifference / priceOriginalProduct) * 100).toFixed(0);
}

export const getSavingsInPercent = (
  originalProduct: TProduct,
  dupe: TDupeProduct
): { value: string; label: string } => {
  const differenceInPercent = getPriceDifference(
    Number(originalProduct.price_de),
    Number(dupe.data.price_de)
  );

  if (Number(differenceInPercent) === 0) {
    return {
      value: `${differenceInPercent} %`,
      label: "",
    };
  }

  if (differenceInPercent?.includes("-")) {
    return {
      value: `${Math.abs(Number(differenceInPercent.replace("-", ""))).toFixed(
        0
      )} %`,
      label: "SHARED.MORE_EXPENSIVE_LABEL",
    };
  }

  return {
    value: `${differenceInPercent} %`,
    label: "SHARED.SAVED_LABEL",
  };
};
