import { selectorFamily } from "recoil";
import { deepCopy } from "../../shared";
import { TDupeProduct } from "../../types";
import { matchFilterAtom } from "../atoms";
import { selectedDupeGtinAtom } from "../atoms/selectedDupeGtinAtom";
import { userAtom } from "../atoms/userAtom";
import { selectedProductToDupeSelector } from "./selectedProductToDupeSelector";
import { findDupeProductsSelector } from "./findDupeProductsSelector";
import { getSavingsInPercent } from "../../components/carousell/DupeCarousell/utils";
import { isPriceInRange } from "../../v2/utils/priceInRange";

export const matchingDupesSelector = selectorFamily<
  TDupeProduct[],
  string | undefined
>({
  key: "matchingDupesSelector",
  get:
    (gtin: string | undefined) =>
    async ({ get }) => {
      const selectedDupeGtin = gtin ? gtin : get(selectedDupeGtinAtom);

      get(userAtom); // This is here to force a re-render when the user changes

      if (!selectedDupeGtin) {
        return [];
      }

      const dupeProducts = get(findDupeProductsSelector(selectedDupeGtin));

      const matchFilter = get(matchFilterAtom);

      if (matchFilter === "price") {
        const onlyDupesWithPrice = dupeProducts.filter((dupe) =>
          isPriceInRange(Number(dupe?.data.price_de))
        );

        const productsSortedByPrice = sortProductsByPrice(
          deepCopy(onlyDupesWithPrice)
        );
        // trackProductListImpression(productsSortedByPrice, matchFilter);
        return productsSortedByPrice;
      }

      if (matchFilter === "match") {
        const productsSortedByBestMatch = sortProductsByBestMatch(
          deepCopy(dupeProducts)
        );
        // trackProductListImpression(productsSortedByBestMatch, matchFilter);
        return productsSortedByBestMatch;
      }

      return dupeProducts;
    },
});

type TMatchDetailGtins = {
  originGtin: string | undefined;
  matchGtin: string | undefined;
};

export interface ISelectedMatch extends TDupeProduct {
  savings: { value: string; label: string };
}
export const selectedMatchSelector = selectorFamily<
  ISelectedMatch | null,
  TMatchDetailGtins
>({
  key: "selectedMatchSelector",
  get:
    ({ originGtin, matchGtin }: TMatchDetailGtins) =>
    ({ get }) => {
      if (!originGtin || !matchGtin) return null;

      const originProduct = get(selectedProductToDupeSelector(originGtin));

      if (!originProduct) return null;

      const matchingDupes = get(matchingDupesSelector(originGtin));
      const selectedMatch = matchingDupes.find(
        (dupe) => dupe.gtin_twinproduct === matchGtin
      );

      if (!selectedMatch) return null;

      const savings = getSavingsInPercent(originProduct, selectedMatch);

      return {
        ...selectedMatch,
        savings,
      };
    },
});

export const sortProductsByPrice = (
  dupeProducts: TDupeProduct[]
): TDupeProduct[] =>
  dupeProducts.sort(
    (a, b) => Number(a.data.price_de) - Number(b.data.price_de)
  );

export const sortProductsByBestMatch = (
  dupeProducts: TDupeProduct[]
): TDupeProduct[] =>
  dupeProducts.sort((a, b) => parseInt(b.sim_total) - parseInt(a.sim_total));
